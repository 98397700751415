@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  p {
    all: unset;
  }
}


.your-element {
    /* Your initial styles here */
    opacity: 0; /* Initially, set the opacity to 0 */
    transform: translateY(20px); /* Optional: Initial position offscreen */
    transition: opacity 1s, transform 1s;
  }
  
  .fade {
    opacity: 1; /* When the element comes into view, fade in */
    transform: translateY(0); /* Optional: Reset the element's position */
  }
  /* Add the hover styles for the shadow effect */
.hover-item {
  transition: transform 0.3s ease-in-out;
}

.hover-item:hover {
  transform: translateY(-5px); /* Adjust as needed for the lift effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect on hover */
}





